import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,444.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
          <path d="M4930 3174 l-265 -151 -3 -309 -2 -310 252 -144 c138 -80 259 -149
268 -153 11 -6 53 13 140 63 69 40 132 76 141 81 14 7 32 -18 109 -151 50 -88
95 -160 98 -160 7 1 472 801 472 813 0 4 -92 7 -205 7 l-204 0 -3 131 -3 130
-265 153 -265 152 -265 -152z m1033 -552 c-16 -26 -86 -150 -158 -274 -71
-125 -133 -227 -136 -228 -5 0 -308 517 -317 541 -2 5 134 9 317 9 l321 0 -27
-48z"/>
<path d="M5152 1800 c-80 -17 -112 -54 -119 -141 -5 -54 -8 -59 -29 -59 -21 0
-24 -5 -24 -40 0 -36 3 -40 25 -40 l25 0 0 -200 0 -200 65 0 65 0 0 200 0 200
50 0 50 0 0 40 0 40 -50 0 -50 0 0 43 c0 53 13 67 62 67 l38 0 0 45 c0 29 -4
45 -12 45 -7 0 -22 2 -33 4 -11 2 -39 0 -63 -4z"/>
<path d="M4530 1669 l0 -120 -23 26 c-52 61 -198 51 -275 -19 -51 -46 -67 -93
-67 -202 0 -94 2 -104 30 -149 40 -64 97 -95 176 -95 71 0 107 13 136 48 l23
27 0 -32 0 -33 65 0 65 0 0 335 0 335 -65 0 -65 0 0 -121z m-36 -178 c28 -29
31 -37 35 -111 4 -90 -8 -130 -47 -160 -27 -22 -100 -27 -133 -10 -52 28 -77
166 -45 245 33 78 129 96 190 36z"/>
<path d="M5670 1668 l0 -123 -23 27 c-41 50 -147 58 -227 18 -98 -50 -147
-174 -120 -304 23 -111 100 -175 211 -176 75 0 114 15 138 53 l21 32 0 -37 0
-38 65 0 65 0 0 335 0 335 -65 0 -65 0 0 -122z m-62 -158 c18 -11 40 -36 48
-55 31 -76 6 -217 -44 -244 -12 -6 -42 -11 -67 -11 -38 0 -52 5 -75 28 -45 46
-60 120 -39 197 25 91 104 129 177 85z"/>
<path d="M4787 1762 c-38 -42 -5 -112 52 -112 20 0 36 9 50 26 25 32 26 52 5
82 -20 28 -83 31 -107 4z"/>
<path d="M2692 1723 c-92 -253 -212 -588 -212 -595 0 -4 29 -8 64 -8 l64 0 32
95 32 96 105 -3 105 -3 29 -92 30 -93 69 0 69 0 -38 108 c-21 59 -71 200 -111
315 l-73 207 -78 0 c-75 0 -77 -1 -87 -27z m121 -200 l35 -113 -73 0 -73 0 37
114 c20 62 37 113 37 112 1 0 18 -52 37 -113z"/>
<path d="M6450 1435 l0 -317 168 4 c190 4 228 15 297 83 54 55 78 115 83 211
6 115 -15 182 -76 243 -70 70 -114 82 -309 88 l-163 6 0 -318z m295 204 c84
-23 133 -115 122 -229 -12 -131 -79 -190 -219 -190 l-78 0 0 215 0 215 68 0
c37 0 85 -5 107 -11z"/>
<path d="M7622 1660 l4 -60 -38 0 c-38 0 -38 0 -38 -40 0 -40 0 -40 39 -40 36
0 39 -2 35 -22 -3 -13 -2 -92 2 -176 6 -140 8 -156 29 -180 21 -24 31 -27 104
-30 l81 -4 0 46 c0 46 0 46 -35 46 -24 0 -37 6 -45 19 -5 11 -10 83 -10 160
l0 141 50 0 50 0 0 40 0 40 -50 0 -50 0 0 60 0 60 -66 0 -66 0 4 -60z"/>
<path d="M3268 1606 c-110 -30 -172 -122 -171 -254 1 -152 86 -242 229 -242
116 0 197 54 224 151 l9 29 -64 0 c-57 0 -64 -2 -70 -22 -14 -45 -45 -68 -92
-68 -76 0 -110 57 -106 183 3 101 57 159 132 141 29 -7 40 -17 56 -51 l20 -42
61 -1 62 0 -8 35 c-24 108 -159 176 -282 141z"/>
<path d="M3790 1609 c-68 -13 -120 -55 -154 -122 -62 -124 -27 -277 78 -342
46 -28 56 -30 141 -30 84 0 95 2 141 30 155 96 145 361 -16 441 -53 27 -128
36 -190 23z m115 -95 c47 -22 70 -73 69 -154 -1 -105 -41 -160 -119 -160 -67
0 -106 41 -118 125 -12 82 20 165 74 192 32 17 54 16 94 -3z"/>
<path d="M6059 1611 c-74 -15 -130 -63 -148 -128 l-10 -33 65 0 c57 0 64 2 64
19 0 33 49 64 90 57 43 -7 60 -29 60 -78 l0 -38 -67 0 c-141 -1 -223 -62 -223
-165 0 -87 54 -135 153 -135 71 0 111 15 128 48 l13 27 4 -32 3 -33 62 0 62 0
-6 192 c-6 218 -13 244 -77 277 -44 22 -123 32 -173 22z m121 -316 c0 -63 -32
-95 -93 -95 -51 0 -67 13 -67 55 0 56 38 82 123 84 l37 1 0 -45z"/>
<path d="M7226 1609 c-61 -15 -103 -46 -125 -93 -31 -63 -29 -66 38 -66 56 0
59 1 71 31 13 31 43 49 81 49 32 0 69 -44 69 -83 l0 -34 -83 -5 c-128 -7 -203
-57 -213 -144 -11 -84 59 -154 151 -154 69 0 112 16 130 49 l14 26 7 -32 c6
-33 7 -33 64 -33 l58 0 -5 178 c-2 97 -6 191 -9 208 -4 30 -40 72 -79 92 -31
15 -126 22 -169 11z m134 -304 c0 -47 -25 -93 -54 -101 -78 -19 -137 27 -106
84 17 30 65 51 123 51 36 1 37 0 37 -34z"/>
<path d="M8066 1609 c-61 -15 -103 -46 -125 -93 -31 -63 -29 -66 38 -66 56 0
59 1 71 31 13 31 43 49 81 49 32 0 69 -44 69 -83 l0 -34 -83 -5 c-128 -7 -203
-57 -213 -144 -11 -84 59 -154 151 -154 69 0 112 16 130 49 l14 26 7 -32 c6
-33 7 -33 67 -33 l60 0 -6 68 c-4 37 -7 124 -7 193 0 114 -2 129 -23 159 -12
19 -36 42 -52 51 -38 22 -128 31 -179 18z m134 -304 c0 -47 -25 -93 -54 -101
-78 -19 -137 27 -106 84 17 30 65 51 123 51 36 1 37 0 37 -34z"/>
<path d="M4780 1360 l0 -240 60 0 60 0 0 240 0 240 -60 0 -60 0 0 -240z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
